import style from './index.module.scss';

import React, {MutableRefObject, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import config from '../../../../service/config/config';
import rainbowStyle from '../../../../theme/style/rainbow.module.scss';
import {useTranslation} from "react-i18next";
import {LanguageGlobe} from "../../../../theme/component/language-globe";

export default function FooterSection() {
    const [serviceToolsActivated, setServiceToolsActivated] = useState<boolean>((localStorage.getItem('service_tools_activated') === 'true'))

    const refNavigation = useRef<HTMLUListElement>()
    const refLegal = useRef<HTMLUListElement>()
    const refConnect = useRef<HTMLUListElement>()
    const { t } = useTranslation()

    function toggleDevTools() {
        setServiceToolsActivated(!serviceToolsActivated)
        localStorage.setItem('service_tools_activated', (serviceToolsActivated ? 'false' : 'true'))
    }

    return <div className={`${style.footer} protected`}>
        <section className={style.container}>
            <div>
                <ul className={style.collapsed} onClick={() => toggle(refNavigation)} ref={refNavigation}>
                    <li><p className={`uppercase ${style.title} pre-icon ${style.pre_icon}  ${style.icon_plus}`}>{t('footer.navigation')}</p></li>
                    <li><Link to="/about">{t('footer.about')}</Link></li>
                    <li><Link to="/blog">{t('footer.blog')}</Link></li>
                    <li><Link to="/upcoming">{t('footer.upcoming')}</Link></li>
                    <li><Link to="/guide">{t('footer.guide')}</Link></li>
                </ul>
                <ul className={style.collapsed} onClick={() => toggle(refLegal)} ref={refLegal}>
                    <li><p className={`uppercase ${style.title} pre-icon ${style.pre_icon} ${style.icon_plus}`}>{t('footer.legal')}</p></li>
                    <li><Link to="/imprint">{t('footer.imprint')}</Link></li>
                    <li><Link to="/terms">{t('footer.terms')}</Link></li>
                    <li><Link to="/privacy">{t('footer.privacy')}</Link></li>
                </ul>
                <ul className={style.collapsed} onClick={() => toggle(refConnect)} ref={refConnect}>
                    <li><p className={`uppercase ${style.title} pre-icon ${style.pre_icon}  ${style.icon_plus}`}>{t('footer.connect')}</p></li>
                    <li><a href="mailto:info@influspace.com">{t('footer.email')}</a></li>

                    <li><Link to="/support">{t('footer.support')}</Link></li>
                    <DevTools serviceToolsActivated={serviceToolsActivated}/>
                    <Backoffice serviceToolsActivated={serviceToolsActivated}/>

                </ul>
                <ul className={style.copy}>
                    <li><p className={`${style.copy} ${style.title}`} onDoubleClick={toggleDevTools}>{t('footer.copyright')}</p></li>
                    <li><LanguageGlobe/></li>

                </ul>
            </div>
        </section>
    </div>
}

function DevTools({serviceToolsActivated}: { serviceToolsActivated: boolean }) {
    if (config.isProduction || serviceToolsActivated === false)
        return <></>

    return <li><Link to='/dev' className={rainbowStyle.orange_red_gradient}>Playgrounds</Link></li>
}

function Backoffice({serviceToolsActivated}: { serviceToolsActivated: boolean }) {
    if (config.isProduction || serviceToolsActivated === false)
        return <></>

    return <li><Link to='/backoffice' className={rainbowStyle.orange_red_gradient}>Backoffice</Link></li>
}

function toggle(ref: MutableRefObject<HTMLUListElement>) {
    ref.current.classList.toggle(style.collapsed)
}