import Overlay from "../../../../../../theme/component/overlay/overlay.component";
import InputButton from "../../../../../../theme/component/input-button";
import React, { useState } from "react";
import { DropdownPanel } from "../../../../../../theme/component/dropdown-panel/dropdown-panel.component";
import style from './index.module.scss';
import { useTranslation } from 'react-i18next';

type InstagramConnectTutorialProps = {
    open: boolean;
    onContinue: () => void;
    onClose: () => void;
};

export function InstagramConnectTutorial({ open, onContinue, onClose }: InstagramConnectTutorialProps) {
    const { t } = useTranslation();
    const [openInstagramAccountTutorial, setOpenInstagramAccountTutorial] = useState<boolean>(false);

    return (
        <Overlay title={t('instagram_tutorial.connectInstagram')} open={open} onClose={onClose} disableTapout={false}>
            <div className='mt2'>{t('instagram_tutorial.connectInstagramDescription')}</div>
            <div className='mt3'>
                <DropdownPanel label={t('instagram_tutorial.changeInstagramAccountType')} open={openInstagramAccountTutorial} onOpen={value => setOpenInstagramAccountTutorial(value)}>
                    <div className={style.tutorial_container}>
                        {t('instagram_tutorial.instagramStep1')}<br />
                        {t('instagram_tutorial.instagramStep2')} <span className={style.marked}>{t('instagram_tutorial.settings')}</span><br />
                        {t('instagram_tutorial.instagramStep3')} <span className={style.marked}>{t('instagram_tutorial.accountTypeTools')}</span><br />
                        {t('instagram_tutorial.instagramStep4')}<br />
                        {t('instagram_tutorial.instagramStep5')} <span className={style.marked}>{t('instagram_tutorial.business')}</span> {t('instagram_tutorial.or')} <span className={style.marked}>{t('instagram_tutorial.creator')}</span>
                    </div>
                </DropdownPanel>
            </div>
            <div className='mt3 float-right'><InputButton label={t('instagram_tutorial.continue')} onClick={onContinue} /></div>
        </Overlay>
    );
}
