import React from "react";
import style from './index.module.scss';
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

export function HeroCall() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return <div className={style.container}>
            <span className={style.logo_inner_container}>
                <span className={style.logo} />
                <span className={style.brand}>{t('hero_section_appname')}</span>
            </span>
            <h1 className={style.big_text}>{t('hero_section_headline_firstline')}<br/>{t('hero_section_headline_secondline')}</h1>
            <div className={style.small_text}>{t('hero_section_subtitle')}</div>
            <span className={style.button_bar}>
                <span className={style.signup_button} onClick={() => navigate('/join')}>{t('hero_section_join')}</span>
                <span className={style.see_more_button}
                      onClick={() => scrollToSection('benefits')}>{t('hero_section_see_more')}</span>
            </span>
        </div>
}