import style from "./index.module.scss"
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import phoneSignup from '../../../../theme/resource/image/landing/phone_mask_1.png';
import phoneDiscover from '../../../../theme/resource/image/landing/phone_mask_2.png';
import phoneConnect from '../../../../theme/resource/image/landing/phone_mask_3.png';
import phoneCooperate from '../../../../theme/resource/image/landing/phone_mask_2.png';
import {classNames} from "../../../../utility/className.utility";

export function Benefits2() {
    const { t } = useTranslation();
    const [selectedScreenshot, setSelectedScreenshot] = useState(phoneSignup);

    // Click handlers for each benefit
    const handleClick = (screenshot: string) => {
        setSelectedScreenshot(screenshot);
    };

    return (
        <div className={style.container} id={"benefits"}>
            <div className={style.benefitsContainer}>
                <h2 className={style.title}>The Best Benefits</h2>

                {/* Left Benefits Grid */}
                <div className={style.benefitsGrid}>
                    <div className={style.benefitItem} onClick={() => handleClick(phoneSignup)}>
                        <div className={classNames(style.benefit_icon, style.login_icon)} />
                        <div className={style.benefitText}>
                            <h3>Sign up</h3>
                            <p>Erstelle ein Konto, um loszulegen</p>
                        </div>
                    </div>
                    <div className={style.benefitItem} onClick={() => handleClick(phoneDiscover)}>
                        <div className={classNames(style.benefit_icon, style.discover_icon)} />
                        <div className={style.benefitText}>
                            <h3>Entdecken</h3>
                            <p>Entdecke neue Kooperationspartner</p>
                        </div>
                    </div>
                </div>

                {/* Phone Mockup */}
                <div className={style.phoneMockup}>
                    <img src={selectedScreenshot} alt="Phone Mockup" />
                </div>

                {/* Right Benefits Grid */}
                <div className={style.benefitsGrid}>
                    <div className={style.benefitItem} onClick={() => handleClick(phoneConnect)}>
                        <div className={classNames(style.benefit_icon, style.connect_icon)} />
                        <div className={style.benefitText}>
                            <h3>Verbinden</h3>
                            <p>Vernetze dich mit Kooperationspartnern</p>
                        </div>
                    </div>
                    <div className={style.benefitItem} onClick={() => handleClick(phoneCooperate)}>
                        <div className={classNames(style.benefit_icon, style.cooperation_icon)} />
                        <div className={style.benefitText}>
                            <h3>Kooperation Starten</h3>
                            <p>Schließt Kooperationen und wachst zusammen</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
