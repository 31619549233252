import React, {useState} from "react";
import {IBackofficeOffer} from "../../../../../../service/marketplace/model/offer.model";
import {OfferOverview} from "../../../../../../influspace/marketplace/component/offer/component/overview";
import InputButton from "../../../../../../theme/component/input-button";
import {ButtonColor} from "../../../../../../theme/component/input-button/models/button-color.model";
import {
    DeleteOfferDisclaimer
} from "../../../../../../influspace/marketplace/component/offer-detail/component/offer/component/delete-disclaimer";

type BackofficeOfferProps = {
    backofficeOffer:  IBackofficeOffer
    onDelete: (ref: string) => void
    onSend: (ref: string) => void
}
export function BackofficeOffer({ backofficeOffer, onDelete, onSend }: BackofficeOfferProps) {

    const [openDisclaimer, setOpenDisclaimer] = useState<boolean>(false)


    return <>
        <DeleteOfferDisclaimer open={openDisclaimer} onDelete={() => {
            onDelete(backofficeOffer.offer.ref)
            setOpenDisclaimer(false)
        }
        } onClose={() => setOpenDisclaimer(false)}/>
        <OfferOverview offer={backofficeOffer.offer}/>
        <div className={'mb4'}>
            <InputButton label={'Delete'} color={ButtonColor.Failure} onClick={() => setOpenDisclaimer(true)}/>
            <span className={'float-right'}><InputButton onClick={() => onSend(backofficeOffer.offer.ref)} disabled={backofficeOffer.sentAsMail} label={'Send as Mail'} color={ButtonColor.Success}/></span>
        </div>
    </>
}