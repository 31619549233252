import {IBackofficeOption} from "./backoffice-option";
import {BackofficeOptionTypes} from "./backoffice-option-types";


export const componentList: IBackofficeOption[] = [
    {
        name: 'Mail Templates',
        description: 'Send and Manage Mail Templates',
        path: '/backoffice/email-templates',
        topic: BackofficeOptionTypes.production
    },
    {
        name: 'Blog Post Creation',
        description: 'Design a Blog Post',
        path: '/backoffice/blog-creation',
        topic: BackofficeOptionTypes.brandCommunications
    },
    {
        name: 'Blog Post Management',
        description: 'Update and Delete Blog Posts',
        path: '/backoffice/blog-management',
        topic: BackofficeOptionTypes.brandCommunications
    },
    {
        name: 'User Statics',
        description: 'Metrics on User Activity',
        topic: BackofficeOptionTypes.analytics,
        path: '/backoffice/user-statistics'
    },
    {
        name: 'Newsletter Subscriptions',
        description: 'Manage Newsletter Subscriptions',
        topic: BackofficeOptionTypes.analytics,
        path: '/backoffice/newsletter-subscriptions'
    },
    {
        name: 'Support Tickets',
        description: 'Manage Support Tickets',
        topic: BackofficeOptionTypes.moderation,
        path: '/backoffice/support'
    },
    {
        name: 'Campaigns',
        description: 'Manage Campaigns',
        topic: BackofficeOptionTypes.moderation,
        path: '/backoffice/campaigns'
    },
    // {
    //     name: 'Usage Analytics',
    //     description: 'Source and Interaction Metrics',
    //     topic: BackofficeOptionTypes.analytics,
    //     path: '/backoffice/analytics'
    // },
    // {
    //     name: 'Userbase Metrics',
    //     description: 'User Reports & Statistics',
    //     topic: BackofficeOptionTypes.analytics,
    //     path: '/backoffice/profiles'
    // },
    // {
    //     name: 'Reported Messages',
    //     description: 'Message Moderation Console',
    //     path: '/backoffice/reported-message',
    //     topic: BackofficeOptionTypes.moderation
    // },
    // {
    //     name: 'Reported Profiles',
    //     description: 'Manage Profile Reports',
    //     path: '/backoffice/reported-profile',
    //     topic: BackofficeOptionTypes.moderation
    // },
    // {
    //     name: 'Reported Campaigns',
    //     description: 'Manage Reported Campaigns',
    //     path: '/backoffice/reported-campaigns',
    //     topic: BackofficeOptionTypes.moderation
    // },
    // {
    //     name: 'Administration Privileges',
    //     description: 'Manage Administrators',
    //     path: '/backoffice/manage-administrator',
    //     topic: BackofficeOptionTypes.administration
    // },
    // {
    //     name: 'Service Configuration',
    //     description: 'View Service Configuration',
    //     path: '/backoffice/config',
    //     topic: BackofficeOptionTypes.developer
    // }
]
