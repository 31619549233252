import axios from 'axios'

export class NewsletterApi {

    private static root = 'newsletter'
    private static client = axios

    static async subscribe(mail: string, language: string) : Promise<void> {
        await this.client.post(`${this.root}/`, {
            mail, language
        })
    }

    static async unsubscribe(ref: string) : Promise<void> {
        await this.client.delete(`${this.root}?ref=${ref}`)
    }


    static async getSubscription(ref: string) : Promise<string> {
        const response = await this.client.get(`${this.root}?ref=${ref}`)
        return response.data.mail
    }


}
