import React from 'react'
import Panel from '../../component/panel/panel.component'
import Menu from './component/menu/menu'
import Search from './component/search/search.component'
import User from './component/user/user.component'
import Logo from './component/logo/logo.component'

import style from './navbar.module.scss'

type NavbarProps = {
  loggedIn: boolean
}

export default function Navbar({ loggedIn }: NavbarProps) {
  return (
    <Panel className={`${style.navbar} protected`}>
        <Logo />
        <Search />
        <Menu />
        <User loggedIn={loggedIn} />
    </Panel>
  )
}