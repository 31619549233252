import React, { useEffect, useState } from "react";
import InputDropdown from "../../../../../../theme/component/input-dropdown/input-dropdown.component";
import { TargetAge } from "../../model/demography.model";
import OptionModel from '../../../../../../theme/component/input-dropdown/models/dropdown-option.model'
import {useTranslation} from "react-i18next";
import style from './index.module.scss'

type ProfileAgeProps = {
    targetAge: TargetAge
    editingMode: boolean
    onChange: (targetAge: TargetAge) => void

}
export default function ProfileAge({targetAge, editingMode, onChange}: ProfileAgeProps) {
    const { t } = useTranslation()

    const options: OptionModel[] = [
        { label: t('profile_age.range_kids'), value: TargetAge.Kids },
        { label: t('profile_age.range_teen'), value: TargetAge.Teens },
        { label: t('profile_age.range_adult'), value: TargetAge.Adults },
        { label: t('profile_age.range_senior'), value: TargetAge.Elderly },
        { label: t('profile_age.range_not_specified'), value: TargetAge.NotSpecified },
    ]
    
    const [initialIndex, setInitialIndex] = useState<number>()

    useEffect(() => {
        setInitialIndex(options.findIndex((element) => element.value === targetAge))
    }, [editingMode, targetAge])

    function onChangeHandler(optionModel: OptionModel) {
        const index = Object.values(TargetAge).indexOf(optionModel.value as unknown as TargetAge);
        const key: string = Object.keys(TargetAge)[index];

        onChange(TargetAge[key as keyof typeof TargetAge])
    }


    if (editingMode === true) {
        return (
            <span className="l-12 mt1">
                <span className={'l-3 m-4'}>{t('profile_age.title')}</span>
                <span className="l-4 m-6 ml1">
                    <InputDropdown options={options} initialIndex={initialIndex} onChange={onChangeHandler}></InputDropdown>
                </span>
            </span>
        )
    } else if (targetAge !== TargetAge.NotSpecified) {
        return (
            <div className='l-12 mt2'>
                <span className='l-3 m-4'>{t('profile_age.title')}</span>
                <span className={style.selection}>{options?.find(option => option.value === targetAge)?.label}</span>
            </div>
            )
    } else {
        return (<></>)
    }

    
}
