import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputButton from "../../../../../../../../theme/component/input-button";
import style from './image-cropper.module.scss';
import { ButtonColor } from "../../../../../../../../theme/component/input-button/models/button-color.model";
import ScaleIcon from '../../../../../../../../theme/resource/reactIcons/scale';

interface ImageCropperProps {
    imageUrl: string;
    imageType?: 'profile' | 'banner';
    onCrop: (croppedImage: File, cropData: { x: number, y: number, width: number, height: number }) => void;
    onCancel: () => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({ imageUrl, imageType, onCrop, onCancel }) => {
    const [cropBoxPosition, setCropBoxPosition] = useState({ x: 0, y: 0 });
    const [cropBoxSize, setCropBoxSize] = useState({ width: 0, height: 0 });
    const imageRef = useRef<HTMLImageElement>(null);
    const cropBoxRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const aspectRatio = imageType === 'banner' ? 800 / 190 : 1;

    useEffect(() => {
        const updateDimensions = () => {
            if (containerRef.current) {
                const screenWidth = window.innerWidth;
                const screenHeight = window.innerHeight;
                const containerWidth = containerRef.current.offsetWidth;
                const containerHeight = containerRef.current.offsetHeight;

                let newWidth, newHeight;

                if (imageType === 'banner') {
                    newWidth = Math.min(containerWidth, screenWidth * 0.8);
                    newHeight = newWidth / aspectRatio;
                } else {
                    newWidth = Math.min(containerWidth, containerHeight, screenWidth * 0.8, screenHeight * 0.8);
                    newHeight = newWidth;
                }

                setCropBoxSize({ width: newWidth, height: newHeight });

                // Center the crop box
                const newX = (containerWidth - newWidth) / 2;
                const newY = (containerHeight - newHeight) / 2;
                setCropBoxPosition({ x: newX, y: newY });
            }
        };

        updateDimensions();
        window.addEventListener('resize', updateDimensions);

        document.body.style.overflow = 'hidden';
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';

        return () => {
            window.removeEventListener('resize', updateDimensions);

            document.body.style.overflow = '';
            document.body.style.position = '';
            document.body.style.width = '';
        };
    }, [imageType, aspectRatio]);

    const handleCrop = () => {
        if (imageRef.current && cropBoxRef.current) {
            const image = imageRef.current;
            const cropBox = cropBoxRef.current;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;

                canvas.width = cropBoxSize.width;
                canvas.height = cropBoxSize.height;

                ctx.drawImage(
                    image,
                    cropBoxPosition.x * scaleX,
                    cropBoxPosition.y * scaleY,
                    cropBoxSize.width * scaleX,
                    cropBoxSize.height * scaleY,
                    0,
                    0,
                    cropBoxSize.width,
                    cropBoxSize.height
                );

                canvas.toBlob((blob) => {
                    if (blob) {
                        const croppedFile = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
                        onCrop(croppedFile, {
                            x: cropBoxPosition.x * scaleX,
                            y: cropBoxPosition.y * scaleY,
                            width: cropBoxSize.width * scaleX,
                            height: cropBoxSize.height * scaleY
                        });
                    }
                }, 'image/jpeg');
            }
        }
    };

    const onMoveStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        const cropBox = cropBoxRef.current;
        const container = containerRef.current;
        if (!cropBox || !container) return;

        let startX: number, startY: number;
        if ('touches' in e) {
            startX = e.touches[0].clientX - cropBoxPosition.x;
            startY = e.touches[0].clientY - cropBoxPosition.y;
        } else {
            startX = e.clientX - cropBoxPosition.x;
            startY = e.clientY - cropBoxPosition.y;
        }

        const onMove = (e: MouseEvent | TouchEvent) => {
            let clientX: number, clientY: number;
            if ('touches' in e) {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            } else {
                clientX = e.clientX;
                clientY = e.clientY;
            }

            let newX = clientX - startX;
            let newY = clientY - startY;

            newX = Math.max(0, Math.min(newX, container.offsetWidth - cropBoxSize.width));
            newY = Math.max(0, Math.min(newY, container.offsetHeight - cropBoxSize.height));

            setCropBoxPosition({ x: newX, y: newY });
        };

        const onEnd = () => {
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onEnd);
            document.removeEventListener('touchmove', onMove);
            document.removeEventListener('touchend', onEnd);
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onEnd);
        document.addEventListener('touchmove', onMove, { passive: false });
        document.addEventListener('touchend', onEnd);
    }, [cropBoxPosition, cropBoxSize]);

    const onResizeStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const cropBox = cropBoxRef.current;
        const container = containerRef.current;
        if (!cropBox || !container) return;

        let startX: number, startY: number;
        if ('touches' in e) {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        } else {
            startX = e.clientX;
            startY = e.clientY;
        }

        let startWidth = cropBoxSize.width;
        let startHeight = cropBoxSize.height;

        const onMove = (e: MouseEvent | TouchEvent) => {
            let clientX: number, clientY: number;
            if ('touches' in e) {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            } else {
                clientX = e.clientX;
                clientY = e.clientY;
            }

            let newWidth = startWidth + clientX - startX;
            let newHeight = newWidth / aspectRatio;

            // Ensure the crop box stays within the image bounds
            newWidth = Math.min(newWidth, container.offsetWidth - cropBoxPosition.x);
            newHeight = Math.min(newHeight, container.offsetHeight - cropBoxPosition.y);

            // Adjust width based on height constraints
            if (newHeight === container.offsetHeight - cropBoxPosition.y) {
                newWidth = newHeight * aspectRatio;
            }

            setCropBoxSize({ width: newWidth, height: newHeight });
        };

        const onEnd = () => {
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onEnd);
            document.removeEventListener('touchmove', onMove);
            document.removeEventListener('touchend', onEnd);
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onEnd);
        document.addEventListener('touchmove', onMove, { passive: false });
        document.addEventListener('touchend', onEnd);
    }, [cropBoxPosition, cropBoxSize, aspectRatio]);

    const cropBoxStyle = {
        width: `${cropBoxSize.width}px`,
        height: `${cropBoxSize.height}px`,
        borderRadius: imageType === 'profile' ? '50%' : '0',
    };

    const getResizeHandleStyle = () => {
        if (imageType === 'profile') {
            const radius = cropBoxSize.width / 2;
            const angle = Math.PI / 4; // 45 degrees
            return {
                left: `${radius + radius * Math.cos(angle) - 20}px`,
                top: `${radius + radius * Math.sin(angle) - 20}px`,
                cursor: 'nwse-resize'
            };
        }
        return {
            right: '-10px',
            bottom: '-10px',
            cursor: 'se-resize'
        };
    };

    return (
        <div className={style.modalOverlay}>
            <div className={style.modal}>
                <h2 className={style.modalTitle}>Crop {imageType === 'profile' ? 'Profile Picture' : 'Banner'}</h2>
                <div className={style.imageContainer} ref={containerRef}>
                    <img
                        ref={imageRef}
                        src={imageUrl}
                        alt="Selected"
                        className={style.selectedImage}
                    />
                    <div
                        ref={cropBoxRef}
                        className={style.cropBox}
                        style={{
                            ...cropBoxStyle,
                            left: cropBoxPosition.x,
                            top: cropBoxPosition.y,
                        }}
                        onMouseDown={onMoveStart}
                        onTouchStart={onMoveStart}
                    >
                        <div
                            className={style.resizeHandle}
                            style={getResizeHandleStyle()}
                            onMouseDown={onResizeStart}
                            onTouchStart={onResizeStart}
                        >
                            <ScaleIcon size={16}/>
                        </div>
                    </div>
                </div>
                <div className={style.modalFooter}>
                    <InputButton label="Cancel" color={ButtonColor.Failure} onClick={onCancel} />
                    <InputButton label="Upload Image" onClick={handleCrop} />
                </div>
            </div>
        </div>
    );
};

export default ImageCropper;