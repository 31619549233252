import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import style from './index.module.scss';
import { useNavigate } from "react-router-dom";
import useQuery from "../../theme/hooks/use-query.component";
import { NewsletterApi } from "../../api/newsletter/newsletter.api";
import { NotificationService } from "../../service/notification/notification.service";
import { NotificationType } from "../../service/notification/model/notification-type.enum";

const NewsletterCancellation: React.FC = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState<string | null>(null);
    const ref = useQuery('ref', null);
    const navigate = useNavigate();

    useEffect(() => {
        NewsletterApi.getSubscription(ref)
            .then(response => {
                setEmail(response);
            })
            .catch(() => {
                navigate('/');
            });
    }, [ref]);

    function onUnsubscribe() {
        NewsletterApi.unsubscribe(ref)
            .then(() => {
                navigate('');
                NotificationService.displayNotification({
                    type: NotificationType.success, message: t('notification.unsubscribed')
                });
            }).catch(() => {
            NotificationService.displayNotification({
                type: NotificationType.failure, message: t('notification.error')
            });
        });
    }

    return (
        <div className={style.unsubscribePanel}>
            <h2>{t('unsubscribe.title')}</h2>
            <h3>{t('unsubscribe.email', { email })}</h3>
            <p>{t('unsubscribe.thankYou')}</p>
            <br/>
            <div className={style.unsubscribeContainer}>
                <button className={style.unsubscribeButton} onClick={onUnsubscribe}>
                    {t('unsubscribe.button')}
                </button>
            </div>
        </div>
    );
};

export default NewsletterCancellation;
