import React, { useEffect, useState } from "react";
import InputDropdown from "../../../../../../theme/component/input-dropdown/input-dropdown.component";
import { TargetGender } from "../../model/demography.model";
import OptionModel from '../../../../../../theme/component/input-dropdown/models/dropdown-option.model'
import {useTranslation} from "react-i18next";
import style from './index.module.scss'

type ProfileGenderProps = {
    targetGender: TargetGender
    editingMode: boolean
    onChange: (targetGender: TargetGender) => void

}
export default function ProfileGender({targetGender, editingMode, onChange}: ProfileGenderProps) {
    const { t } = useTranslation()

    const options: OptionModel[] = [
        { label: t('profile_gender.range_female'), value: TargetGender.Female },
        { label: t('profile_gender.range_male'), value: TargetGender.Male },
        { label: t('profile_gender.range_mixed'), value: TargetGender.Mixed },
        { label: t('profile_gender.range_diverse'), value: TargetGender.Diverse },
        { label: t('profile_gender.range_not_specified'), value: TargetGender.NotSpecified },
    ]

    const [initialIndex, setInitialIndex] = useState<number>()

    useEffect(() => {
        setInitialIndex(options.findIndex((element) => element.value === targetGender))
    }, [editingMode, targetGender])

    function onChangeHandler(optionModel: OptionModel) {
        const index = Object.values(TargetGender).indexOf(optionModel.value as unknown as TargetGender);
        const key: string = Object.keys(TargetGender)[index];

        onChange(TargetGender[key as keyof typeof TargetGender])
    }

    if (editingMode === true) {
        return (
            <div className="l-12 mt1">
                <span className={'l-3 m-4'}>{t('profile_gender.title')}</span>
                    <span className="l-4 m-6 ml1">
                        <InputDropdown options={options} initialIndex={initialIndex} onChange={onChangeHandler}></InputDropdown>
                    </span>
            </div>
        )
    } else if (targetGender !== TargetGender.NotSpecified) {
        return (
            <div className='l-12 mt1'>
                <span className='l-3 m-4'>{t('profile_gender.title')}</span>
                <span className={style.selection}>{options.find(option => option.value === targetGender)?.label}</span>
            </div>
        )
    } else {
        return <></>
    }

    
}
