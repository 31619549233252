import React, { useEffect, useState } from 'react';
import style from '../account-details/account-details.module.scss';
import InputText from '../../../../../../theme/component/input-text/input-text.component';
import ISettings from '../../../../../../service/settings/model/settings.model';
import InputButton from '../../../../../../theme/component/input-button';
import settingsService from '../../../../../../service/settings/settings.service';
import InputCheckbox from '../../../../../../theme/component/input-checkbox/input-checkbox.component';
import { NotificationService } from '../../../../../../service/notification/notification.service';
import { NotificationType } from '../../../../../../service/notification/model/notification-type.enum';
import { useTranslation } from 'react-i18next';

type MailDetailsProps = {
    settings: ISettings;
    onSave: (settings: ISettings) => void;
};

export default function MailDetails({ settings, onSave }: MailDetailsProps) {
    const { t } = useTranslation();

    const [chatAsMail, setChatAsMail] = useState<boolean>(false);
    const [campaignAsMail, setCampaignAsMail] = useState<boolean>(false);
    const [newsletterAsMail, setNewsletterAsMail] = useState<boolean>(false);
    const [disableSaveButton, setDisableSaveButton] = useState<boolean>(false);
    const [username, setUsername] = useState<string>();
    const [mail, setMail] = useState<string>();

    useEffect(() => {
        setChatAsMail(settings?.notificationSettings?.chatAsMail);
        setCampaignAsMail(settings?.notificationSettings?.campaignAsMail);
        setNewsletterAsMail(settings?.notificationSettings?.newsletterAsMail);
    }, [settings]);

    useEffect(() => {
        setUsername(settings?.username);
        setMail(settings?.mail);
    }, [settings]);

    async function onSaveInput(): Promise<void> {
        try {
            setDisableSaveButton(true);
            await settingsService.setNotificationSettings(mail, {
                chatAsMail,
                campaignAsMail,
                newsletterAsMail,
            });
            settings.notificationSettings = {
                chatAsMail,
                campaignAsMail,
                newsletterAsMail,
            };
            settings.mail = mail;
            onSave(Object.assign({}, settings));
            NotificationService.displayNotification({
                message: t('mail.save_success_message'),
                type: NotificationType.success,
            });
        } catch (error) {
            NotificationService.displayNotification({
                message: t('mail.save_failure_message'),
                type: NotificationType.failure,
            });
        }
        setDisableSaveButton(false);
    }

    return (
        <div className="mb2">
            <div className={style.iconWithText}>
                <div className={style.iconMail} />
                <div>
                    <div>{t('mail.title')}</div>
                    <div className={style.info}>{t('mail.manage_details')}</div>
                </div>
            </div>
            <div className='mb1'>
                <InputText label={t('mail.mail')} placeholder={t('mail.change_mail')} initialValue={mail} onChange={setMail} />
            </div>
            <div className='mt1'>{t('mail.set_notifications_as_mail')}</div>
            <InputCheckbox label={t('mail.chat_notification')} checked={chatAsMail} onChange={setChatAsMail} />
            <InputCheckbox label={t('mail.campaign_notification')} checked={campaignAsMail} onChange={setCampaignAsMail} />
            <InputCheckbox label={t('mail.newsletters')} checked={newsletterAsMail} onChange={setNewsletterAsMail} />

            <div className='mt1'>
                <div>
                    <span className={style.buttonL}><InputButton disabled={disableSaveButton} label={t('mail.save')} onClick={onSaveInput} /></span>
                </div>
            </div>
        </div>
    );
}
