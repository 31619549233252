import React, { useEffect, useState } from 'react'
import ProfileView from '../../../../service/profile/model/profile-view.model'
import InlineToast from '../../../../theme/component/inline-notification'
import { InlineToastType } from '../../../../theme/component/inline-notification/model/inline-notification-type.model'
import InputButton from '../../../../theme/component/input-button'
import Panel from '../../../../theme/component/panel/panel.component'
import LinkEditor from './component/link-editor/link-editor.component'
import LinkItem from './component/link-item/link-item.component'
import ProfileLink from './model/profile-link.model'

import style from './profile-link.module.scss'
import {useTranslation} from "react-i18next";

type ProfileLinksProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function ProfileLinks({ profile, editingMode }: ProfileLinksProps) {

    const {t} = useTranslation()

    const [links, setLinks] = useState<ProfileLink[]>(profile?.links)
    const [editorOpen, setEditorOpen] = useState<boolean>(false)
    const addLink = editingMode ? <div className='mt2'><InputButton label={t('profile_links.button_text')} onClick={addLinkHandler} /></div> : <></>
    const [selectedLink, setSelectedLink] = useState<ProfileLink>(undefined)
    const [showToast, setShowToast] = useState<boolean>(false)
    const [toastMessage, setToastMessage] = useState<string>('')
    const [toastType, setToastType] = useState<InlineToastType>(null)

    useEffect(() => {
        setLinks(profile?.links)
    }, [profile])
    
    function addLinkHandler() {
        if (links.length < 5) {
            setSelectedLink(undefined)
            setEditorOpen(true)
        } else {
            setToastMessage('You can only add 5 links in total')
            setToastType(InlineToastType.Failure)
            setShowToast(true)
        }
        
    }

    function removeLink(link: ProfileLink) {
        setLinks(links =>
            links.filter(arrayLink => {
                return arrayLink.ref !== link.ref
            })
        )
    }

    function editLink(link: ProfileLink) {
        setSelectedLink(link)
        setEditorOpen(true)
    }

    function onAddLink(newLink: ProfileLink) {
        links.push(newLink)
        setLinks([...links])
    }

    function onLinkEditorClose() {
        setEditorOpen(false)
        setSelectedLink(undefined)
    }


    // add divider between links and button in editing mode
    
    if (links?.length > 0 || editingMode) {
        const listLinks = links?.map((link) =>  <LinkItem key={link.ref} link={link} editingMode={editingMode} onEdit={editLink} />)
        
        return (
            <Panel overridePadding={style.panel}>
                <InlineToast duration={2000} onClose={() => setShowToast(false)} show={showToast} message={toastMessage} type={toastType} centered={true} />
                <h1 className={style.title}>Links</h1>
                {listLinks}
                <Divider editingMode={editingMode}></Divider>
                {addLink}
                <LinkEditor link={selectedLink} open={editorOpen} username={profile.username} onClose={onLinkEditorClose} onDelete={removeLink} onAdd={onAddLink} />
            </Panel>
        )
    }
    
    return <></>
}

function Divider({editingMode}: {editingMode: boolean}) {
    if(editingMode === false)
        return (<></>)
    else
        return (<hr className='divider' />)
}