import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import SessionDetails from '../../../../../service/session/model/session-details'
import sessionService from '../../../../../service/session/session.service'
import useClickOutside from '../../../../hooks/use-click-outside.component'

import style from './user.module.scss'
import { ProfileType } from "../../../../../service/marketplace/model/offer.model";
import { useTranslation } from "react-i18next";
import SessionService from "../../../../../service/session/session.service";
import ProfileView from "../../../../../service/profile/model/profile-view.model";
import ProfileService from "../../../../../service/profile/profile.service";
import ImageContent from "../../../../component/legacy/image-content-legacy/image-content.component";
import config from "../../../../../service/config/config";
import defaultAvatar from "../../../../resource/image/default/avatar_default.png";

type UserProps = {
    loggedIn: boolean
}

export default function User({ loggedIn }: UserProps) {
    const username = SessionService.getSessionDetails().username
    console.log(username);
    const [avatar, setAvatar] = useState<string>(defaultAvatar)
    const [profile, setProfile] = useState<ProfileView | null>(null)
    const [notFound, setNotFound] = useState<boolean>(false)
    const [serversideError, setServersideError] = useState<boolean>(false)
    const [session, setSession] = useState<SessionDetails>({ authenticated: false, authorized: false, active: false, premium: false })
    const [profileType, setProfileType] = useState<ProfileType | undefined>(undefined)
    const [optionsOpen, setOptionsOpen] = useState<boolean>(false)
    const userRef = useClickOutside(() => setOptionsOpen(false))
    const { t } = useTranslation()
    const location = useLocation()

    function updateSessionDetails() {
        const sessionDetails = sessionService.getSessionDetails()
        setSession(sessionDetails)
        setProfileType(sessionService.getMeDetails().profileType)
    }

    useEffect(() => {
        const abortController = new AbortController()
        const signal = abortController.signal

        function resetErrors() {
            setNotFound(false)
            setServersideError(false)
        }

        async function fetchProfile() {
            try {
                resetErrors()

                if (!username) {
                    console.error("Username is undefined")
                    setNotFound(true)
                    return
                }

                console.log("Fetching profile for username:", username)
                const fetchedProfile = await ProfileService.getProfile(username, signal)
                console.log("Fetched profile:", fetchedProfile)

                setProfile(fetchedProfile)
            } catch (error) {
                console.error("Error fetching profile:", error)
                const response = error.toJSON()

                if (response.status === 404)
                    setNotFound(true)

                if (response.status >= 500)
                    setServersideError(true)
            }
        }

        fetchProfile()

        return () => abortController.abort()
    }, [username])

    useEffect(() => {
        console.log("Profile state updated:", profile)
        if (profile && profile.avatar) {
            const newAvatarUrl = `${config.host}${profile.avatar}`
            console.log("Setting new avatar URL:", newAvatarUrl)
            setAvatar(newAvatarUrl)
        } else {
            console.log("Using default avatar")
            setAvatar(defaultAvatar)
        }
    }, [profile])

    function toggleOptions() {
        setOptionsOpen(!optionsOpen)
    }

    useEffect(() => {
        updateSessionDetails()
    }, [loggedIn])

    useEffect(() => {
        setOptionsOpen(false)
    }, [location])

    if (notFound) {
        console.error("Profile not found for username:", username)
        // You might want to render an error message here
    }

    if (serversideError) {
        console.error("Server-side error occurred while fetching profile")
        // You might want to render an error message here
    }

    if (session.authenticated) {
        return (
            <>
                <span className={style.user} onClick={toggleOptions} ref={userRef}>
                    <span className={style.label}>{session?.username}</span>
                    <ImageContent className={style.avatar_image} src={avatar} alt='Profile Avatar' />
                    <span className={style.icon_user}/>
                </span>
                <span className={`${style.options} ${optionsOpen ? '' : style.closed}`}>
                    <Link to='/settings'
                          className={`${style.pre_icon} ${style.icon_settings}`}>{t('nav.options_settings')}</Link>
                    <Link to='/bookmarks'
                          className={`${style.pre_icon} ${style.icon_bookmarks}`}>{t('nav.options_bookmarks')}</Link>
                    <Link to={`/profile/${session?.username}`}
                          className={`${style.pre_icon} ${style.icon_account}`}>{t('nav.options_profile')}</Link>
                    {!session.premium && profileType === ProfileType.INFLUENCER ? <Link to={`/premium`}
                                                                                        className={`${style.pre_icon} ${style.icon_premium}`}>{t('nav.options_premium')}</Link> : null}
                    <Link to='/boost' className={`${style.pre_icon} ${style.icon_booster}`}>
                        {t('nav.options_booster')} {SessionService.getMeDetails().boosterCount > 0 && (SessionService.getMeDetails().boosterCount + 'x')}
                    </Link>
                    <Link to='/signout'
                          className={`${style.pre_icon} ${style.icon_sign_out}`}>{t('nav.options_logout')}</Link>
                </span>
            </>
        )
    }

    return (
        <Link to='/login' className={style.user}>
            <span className={style.label}>
                <span>{t('nav.options_login')}</span>
            </span>
            <span className={style.icon_sign_in} />
        </Link>
    )
}