import React from "react";
import style from './index.module.scss';
import { useTranslation } from 'react-i18next';

export function Testimonials() {
    const { t } = useTranslation();

    return (
        <section className={style.section}>
            <h1>{t('testimonials.header')}</h1>
            <h3>{t('testimonials.subheader')}</h3>
            <div className={style.testimonial_container}>
                <Testimonial
                    text={t('testimonials.frank.text')}
                    character={'Frank'}
                    company={'FranksCarWash'}
                    icon={'frank'}
                />
                <Testimonial
                    text={t('testimonials.robert.text')}
                    character={'Robert'}
                    company={'MugDesignHub'}
                    icon={'mugdesignhub'}
                />
                <Testimonial
                    text={t('testimonials.giorgio.text')}
                    character={'GiorgioLombisani'}
                    company={'Giorgio'}
                    icon={'giorgio'}
                />
            </div>
        </section>
    );
}

function Testimonial({text, icon, company, character}: {text: string, icon: string, company: string, character: string}) {
    return (
        <span className={style.testimonial}>
            <div className={style.testimonial_text}>"{text}"</div>

            <div className={style.author_container}>
                <span className={`${style.icon} ${style[icon]}`} />
                <div className='mt1 text-bold'>{character}</div>
                <div>{company}</div>
            </div>

        </span>
    );
}
