import Overlay from "../../../../../../../../theme/component/overlay/overlay.component";
import React from "react";
import InputButton from "../../../../../../../../theme/component/input-button";
import { ButtonColor } from "../../../../../../../../theme/component/input-button/models/button-color.model";
import { useTranslation } from "react-i18next";

type DeleteOfferDisclaimer = {
    onDelete?: () => void;
    onClose?: () => void;
    open: boolean;
};

export function DeleteOfferDisclaimer({ onDelete, onClose, open }: DeleteOfferDisclaimer) {
    const { t } = useTranslation();

    return (
        <Overlay title={t('deleteOfferDisclaimer_title')} open={open} onClose={onClose}>
            <div>{t('deleteOfferDisclaimer_message')}</div>
            <div className="mt2">
                <span>
                    <InputButton label={t('deleteOfferDisclaimer_deleteButton')} color={ButtonColor.Failure} onClick={onDelete} />
                </span>
                <span className="float-right">
                    <InputButton label={t('deleteOfferDisclaimer_cancelButton')} onClick={onClose} />
                </span>
            </div>
        </Overlay>
    );
}
