import React, { useState, ChangeEvent, useEffect, useRef } from 'react';
import axios from 'axios';
import style from './index.module.scss';
import imageService from "../../../../../service/image/image.service";
import {ImageType} from "../../../../../service/image/models/image-type.model";
import ImageUpload
    from "../../../../../influspace/profile/component/profile-header/component/image-upload/image-upload.component";
import Tile from "../../../../../influspace/blog/component/tile/index.component";
// import ReactMarkdown from 'react-markdown';

interface StatusOptions {
    [key: string]: string;
}

const CreateBlog = () => {
    const [statusOptions, setStatusOptions] = useState<StatusOptions>({});
    const [formData, setFormData] = useState({
        title: '',
        abstract: '',
        content: '',
        image: '',
        status: ''
    });
    const [message, setMessage] = useState<string>('');

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCreateBlog = () => {
        axios.post('/blog', formData)
            .then(response => {
                setMessage('Blog created successfully!');
                // Reset the form or redirect as needed
            })
            .catch(error => {
                setMessage('Error creating blog. Please try again.');
                console.error('Error creating blog', error);
            });
    };


    const handleImageUpload = (file: File) => {
        console.log(file);
        imageService.uploadImage(file, ImageType.Other)
            .then(response => {
                const url = new URL(response.url, window.location.origin);
                const refId = url.searchParams.get('ref');
                console.log(refId);
                setFormData({ ...formData, image: refId });
                console.log(formData); // Note: formData will not show the updated value here
            })
            .catch(error => {
                console.error('Error uploading image', error);
            });
    };



    async function handleImageUpload2(file: File) {
        try {
            const newImage = await imageService.uploadImage(file, ImageType.Other)
        } catch (err) {
            console.log(err)
        }
    }

    const previewBlog = {
        ...formData,
        ref: 'preview', // Since this is just a preview, the ref can be anything like 'preview'
        createdAt: new Date().toISOString() // or any placeholder date
    };

    return (
        <div className={style.centeredContainer}>

            <section >
                <h2>Create a New Blog Post </h2>
                <div className={style.createBlogContainer}>
                    <div className={style.formGroup}>
                        <label htmlFor="title">Title:</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            className={style.inputField}
                        />
                    </div>
                    <div className={style.imageUploadContainer}>
                        <label htmlFor="status">Image: Nur Im 16:9 Format!!</label>
                        <div className={style.imageUpload}>
                            <ImageUpload editingMode={true} uploadImage={handleImageUpload} />
                        </div>
                    </div>
                    <div className={style.formGroup}>
                        <label htmlFor="abstract">Abstract:</label>
                        <textarea
                            id="abstract"
                            name="abstract"
                            value={formData.abstract}
                            onChange={handleChange}
                            className={style.inputField}
                            rows={5}
                        ></textarea>
                    </div>
                    <div className={style.formGroup}>
                        <label htmlFor="content">Content:</label>
                        <textarea
                            id="content"
                            name="content"
                            value={formData.content}
                            onChange={handleChange}
                            className={style.inputField}
                            rows={15}
                        ></textarea>
                    </div>
                    <div className={style.formGroup}>
                        <label htmlFor="status">Status:</label>
                        <textarea
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className={style.selectField}
                            rows={1}
                        >
                        </textarea>
                    </div>
                    <div className={style.message}>{message}</div>
                    <button type="button" onClick={handleCreateBlog} >
                        Create
                    </button>
                </div>
            </section>
            <section >
                <h2>Newsroom </h2>
                <div className={style.previewSection}>
                    <Tile size="big" blog={previewBlog} />
                    <Tile size="medium" blog={previewBlog} />
                    <Tile size="small" blog={previewBlog} />
                </div>
            </section>
            <section >
                <h2>Page</h2>
                <div className={style.blogDetail}>
                    <h1>{formData.title}</h1>
                    <img src={`http://localhost:25566/api/image?ref=${formData.image}`} alt={"Image"} />
                    <h3>{formData.abstract}</h3>
                    <p className={style.status}>{formData.status.toLocaleUpperCase()}</p>
                    <p className={style.content}>{formData.content}</p>
                    {/*<ReactMarkdown>{formData.content}</ReactMarkdown>*/}
                    <p className={style.createdAt}>{new Date().toLocaleString()}</p>
                </div>
            </section>
        </div>
    );
};

export default CreateBlog;
