import React from "react";
import style from './index.module.scss';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function Navigation() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const scrollToSection = (sectionId: string) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return <>
        <span className={style.top_decoration} />
        <section className={style.section}>
            <span className={style.navbar_option} onClick={() => scrollToSection('about')}>{t('hero_section_about')}</span>
            <span className={style.navbar_option} onClick={() => scrollToSection('pricing')}>{t('hero_section_pricing')}</span>
            <span className={style.navbar_option} onClick={() => scrollToSection('contact')}>{t('hero_section_contact')}</span>
        </section>
    </>
}
