import React from 'react';

type SvgProps = {
    color?: string;
    size?: number;
};

const DealIcon: React.FC<SvgProps> = ({ color = 'rgb(54,54,54)', size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
        <rect x="0.025" y="0" width="16" height="16" style={{ fill: 'none' }} />
        <path
            d="M8.025,0.381c4.205,0 7.619,3.414 7.619,7.619c-0,4.205 -3.414,7.619 -7.619,7.619c-4.205,-0 -7.619,-3.414 -7.619,-7.619c0,-4.205 3.414,-7.619 7.619,-7.619Zm0,0.8c-3.763,0 -6.819,3.056 -6.819,6.819c0,3.763 3.056,6.819 6.819,6.819c3.763,-0 6.819,-3.056 6.819,-6.819c-0,-3.763 -3.056,-6.819 -6.819,-6.819Z"
            style={{ fill: color }}
        />
        <circle cx="8.025" cy="8" r="7.619" style={{ fill: 'rgb(244,244,244)' }} />
        <path
            d="M12.354,12.186l-4.482,-0.108l4.374,-4.373l0.108,4.481Z"
            style={{ fill: color }}
        />
        <path
            d="M9.543,11.795l-2.116,-2.116l2.419,-2.42l2.117,2.117l-2.42,2.419Z"
            style={{ fill: color }}
        />
        <path
            d="M6.081,8.333l-1.619,-1.619l2.419,-2.42l1.619,1.619l-2.419,2.42Z"
            style={{ fill: color }}
        />
        <path
            d="M3.696,3.529l4.482,0.108l-4.374,4.373l-0.108,-4.481Z"
            style={{ fill: color }}
        />
    </svg>
);

export default DealIcon;
