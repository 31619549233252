import React, { useEffect } from 'react'
import ChatDetails from '../../../../../../service/inbox/model/ChatDetails'
import Avatar from './component/avatar'
import style from './index.module.scss'
import { PremiumBadge } from "../../../../../../theme/component/premium-badge"

type ConversationProps = {
    chat: ChatDetails
    selected: boolean
    onSelect: (chat: ChatDetails) => void
    disabled?: boolean
}

export default function Conversation({ chat, selected, onSelect, disabled }: ConversationProps) {
    const selectedStyle = selected ? style.selected : ''
    const disabledStyle = disabled ? style.disabled : ''

    useEffect(() => {
        console.log('chat updated')
        console.log(chat)
    }, [chat]);

    function onSelectHandler(chat: ChatDetails) {
        if (disabled) return
        onSelect(chat)
    }

    const renderLatestMessage = (content: string | undefined): string => {
        if (!content) return ''

        const hasImage = content.includes('[img]')
        const hasText = content.replace(/\[img\].*?\[\/img\]/g, '').trim().length > 0

        if (hasImage && !hasText) {
            return 'An Image was send'
        } else {
            // Truncate the mnessage if it's too long
            return content.length > 50 ? content.substring(0, 47) + '...' : content
        }
    }

    return (
        <div className={`${style.panel} ${selectedStyle} ${disabledStyle}`} onClick={() => onSelectHandler(chat)}>
            <Avatar avatarUrl={chat.chatter.avatar} username={chat.chat} unread={chat.unread} />
            <span className={style.details}>
                <div className={style.displayname}>
                    {chat.chatter.displayname}
                    <PremiumBadge premium={chat.chatter.premium}/>
                </div>
                <div className={style.latest_message}>
                    {renderLatestMessage(chat.latestMessage?.content)}
                </div>
            </span>
        </div>
    )
}