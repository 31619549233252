import axios from 'axios'
import SessionDetails from '../session/model/session-details'
import sessionService from '../session/session.service'
import { JoinError } from './model/join-error.model'
import { JoinForm } from './model/join-form.model'

export class AccountService {

    private static rootUrl = '/account'
    private static client = axios

    static async signIn(login: string, password: string): Promise<SessionDetails> {
        try {
            const response = await this.client.post(`${this.rootUrl}/login`, { login: login, password: password })

            const session = response?.data as SessionDetails

            sessionService.setSessionDetails(session)

            return session
        } catch(error) {
            console.log(error)
            return null
        }
    }

    static async signOut(): Promise<boolean> {
        try {
            const response = await this.client.post(`${this.rootUrl}/signout`)
            if (response.status === 200) {
                await sessionService.signOut()
                return true
            } else {
                return false
            }

        } catch(error) {
            console.log(error)
            return false
        }
    }

    static async join(joinForm: JoinForm): Promise<SessionDetails> {
        try {
            const response = await this.client.post(`${this.rootUrl}/join`, joinForm)
        
            return response.data as SessionDetails
        } catch (error) {
            throw error.response.data as JoinError
        }
    }

    static async thirdPartyJoin(token: string, username: string, birthday: string, type: string, language: string): Promise<SessionDetails> {
        try {
            const response = await this.client.post(`${this.rootUrl}/join/third-party`, {
                token,
                username,
                birthday,
                type,
                language
            })

            return response.data as SessionDetails
        } catch (error) {
            throw error.response.data as JoinError
        }
    }

    static async usernameAvailable(username: string): Promise<boolean> {
        try {
            const response = await this.client.get(`${this.rootUrl}/availability/username`, { params: { username } })

            return response.status === 200
        } catch (error) {
            throw error.response.data
        }
    }

    static async mailAvailable(mail: string): Promise<boolean> {
        try {
            const response = await this.client.get(`${this.rootUrl}/availability/mail`, { params: { mail } })

            return response.status === 200
        } catch (error) {
            throw error.response.data
        }
    }

    static async verify(token: string, signal: AbortSignal): Promise<boolean> {
        try {
            const response = await this.client.post(`${this.rootUrl}/verify/${token}`, { signal })

            return response.data?.verified
        } catch (error) {
            throw error.response.data
        }
    }


}