// http://localhost:3000/share?campaign=e544828e-043a-437b-afb7-365c9a48c884
import React, { useEffect, useState } from "react";
// import style from './index.module.scss';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Panel from "../../../theme/component/panel/panel.component";

export default function ShareCampaign() {

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    return (
        <section>
            <Panel>
                {/*<h1 className={style.header}>{t('brand_access_title')}</h1>*/}
                {/*<h3 className={style.topText}>{t('brand_access_description')}</h3>*/}

            </Panel>
        </section>
    )


}