import React from "react";
import style from "./index.module.scss"
import i18n from "i18next";
import ProfileService from "../../../service/profile/profile.service";
import SessionService from "../../../service/session/session.service";

export function LanguageGlobe() {

    async function onChangeLanguage() {
        const language = i18n.language === 'de' ? 'en' : 'de'
        await i18n.changeLanguage(language)

        try {
            if (SessionService.isAuthenticated()) {
                await ProfileService.setLanguage(language)
            }
        } catch (error) {
        }
    }

    return <span className={style.container} onClick={onChangeLanguage}>
        <span className={style.globe} />
        <span  className={style.language}>{i18n.language !== 'de' ? 'German' : 'Englisch'}</span>
    </span>

}