import React, { useState } from 'react';
import style from './image-upload.module.scss';
import ImageCropper from './componets/image-cropper/image-cropper.component';

interface ImageUploadProps {
    editingMode: boolean;
    uploadImage: (file: File, cropData?: { x: number, y: number, width: number, height: number }) => void;
    imageType?: 'profile' | 'banner';
    useEdge?: boolean;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ editingMode, uploadImage, imageType, useEdge }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [isCropping, setIsCropping] = useState(false);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            setPreviewUrl(URL.createObjectURL(file));
            if (imageType) {
                setIsCropping(true);
            } else {
                // If imageType is not provided, upload the file directly without cropping
                uploadImage(file);
            }
        }
    };

    const handleCrop = (croppedFile: File, cropData: { x: number, y: number, width: number, height: number }) => {
        uploadImage(croppedFile, cropData);
        setIsCropping(false);
    };

    const cancelCrop = () => {
        setIsCropping(false);
        setSelectedFile(null);
        setPreviewUrl(null);
    };

    if (!editingMode) return null;

    const uploadStyle = useEdge ? style.uploadEdge : '';

    return (
        <>
            <label className={`${style.upload} ${uploadStyle}`}>
                <input type='file' accept='image/png,image/jpg,image/jpeg' onChange={handleFileChange}/>
            </label>

            {isCropping && previewUrl && imageType && (
                <ImageCropper
                    imageUrl={previewUrl}
                    imageType={imageType}
                    onCrop={handleCrop}
                    onCancel={cancelCrop}
                />
            )}
        </>
    );
};

export default ImageUpload;