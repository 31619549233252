import React from 'react';

type SvgProps = {
    color?: string;
    size?: number;
};

const DonutIcon: React.FC<SvgProps> = ({ color = 'rgb(54,54,54)', size = 24 }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
    >
        <rect id="marketplace_circled" x="0" y="0" width="16" height="16" style={{ fill: 'none' }} />
        <path
            d="M8,0.381c4.205,0 7.619,3.414 7.619,7.619c-0,4.205 -3.414,7.619 -7.619,7.619c-4.205,-0 -7.619,-3.414 -7.619,-7.619c0,-4.205 3.414,-7.619 7.619,-7.619Zm0,0.8c-3.763,0 -6.819,3.056 -6.819,6.819c0,3.763 3.056,6.819 6.819,6.819c3.763,-0 6.819,-3.056 6.819,-6.819c-0,-3.763 -3.056,-6.819 -6.819,-6.819Z"
            style={{ fill: color }}
        />
        <circle cx="8" cy="8.142" r="5.836" style={{ fill: color }} />
    </svg>
);

export default DonutIcon;