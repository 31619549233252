import React from "react";
import style from './index.module.scss'
import {useTranslation} from "react-i18next";

export function KnownFrom() {
    const {t} = useTranslation()

    return <section className={style.section}>
        <h1>{t('landing.known_from')}</h1>
        <a href="https://www.carwashpro.de/unternehmen/2024/07/16/micro-influencer-fuer-waschanlagen-finden/" target="_blank" rel="noopener noreferrer">
            <span className={style.logo_carwashpro}/>
        </a>
    </section>
}