import React, {useState} from 'react';
import InputText from '../../../../theme/component/input-text/input-text.component';

import style from './index.module.scss';
import {useTranslation} from "react-i18next";
import {NotificationService} from "../../../../service/notification/notification.service";
import {NotificationType} from "../../../../service/notification/model/notification-type.enum";
import i18n from "i18next";
import Cookies from "js-cookie";
import {NewsletterApi} from "../../../../api/newsletter/newsletter.api"; // Ensure this SCSS file contains the new grid styles

export default function NewsletterSection() {
    const { t } = useTranslation();
    const [mail, setMail] = useState('')

    async function onSubscribe() {
        if (!isValidEmail(mail)) {
            NotificationService.displayNotification({
                message: t('newsletter_section.mail_error'),
                type: NotificationType.failure
            })
            return;
        }


        try {
            await NewsletterApi.subscribe(mail, getInitialLanguage())
            NotificationService.displayNotification({
                message: t('newsletter_section.success_message'),
                type: NotificationType.success
            })
        } catch (error) {
        } finally {
            setMail('')
        }
    }
    function isValidEmail(mail: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(mail);
    }

    function getInitialLanguage() {
        if (Cookies.get('language_set')) {
            return i18n.language
        } else {
            return navigator.language === 'de-DE' ? 'de' : 'en'
        }
    }

    return <div className={style.container}>
                <h2>{t('newsletter_section.title')}</h2>
                <p>{t('newsletter_section.call_to_action')}</p>
                <div className={style.section_input}>
                    <span className={style.input_email}>
                        <InputText initialValue={mail} onChange={setMail} placeholder={t('newsletter_section.email_address_field')} />
                    </span>
                    <span className={style.button_send} onClick={onSubscribe}>{t('newsletter_section.subscribe_button')}</span>
                </div>
            </div>
}
