import React, { useEffect, useState } from "react";
import marketplaceService from "../../service/marketplace/marketplace.service";
import style from './index.module.scss'
import OfferCreator from "./component/offer-creator";
import { OfferOverview } from "./component/offer/component/overview";
import { IOffer, ProfileType } from "../../service/marketplace/model/offer.model";
import InputButton from "../../theme/component/input-button";
import { InputFilter } from "../../theme/component/input-filter/input-filter.component";
import FilterOption from "../../theme/component/input-filter/models/filter-option";
import TechnicalError from "../../components/app/component/error/technical/technical-error.component";
import LoadingContent from "../../theme/layout/loading-content/loading-content.component";
import { KeywordFilter } from "./component/keyword-filter";
import ICollaboration from "../profile/component/profile-collaboration/model/collaboration.model";
import { CollaborationFilter } from "./component/collaboration-filter";
import { IKeyword } from "../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";
import { OfferFilter } from "../../service/marketplace/model/offer-filter.model";
import { OfferType } from "../../service/marketplace/model/Offer-type.model";
import { LocationFilter } from "./component/location-filter";
import Overlay from "../../theme/component/overlay/overlay.component";
import Paging from "../../theme/component/paging/paging.componet";
import { v4 as uuid } from 'uuid'
import { useTranslation } from 'react-i18next'



export default function Marketplace() {
    const { t } = useTranslation();

    const offerTypeOptions: FilterOption[] = [
        {
            label: t('marketplace.all_offers'),
            value: 'all'
        },
        {
            label: t('marketplace.my_offers'),
            value: OfferType.OWNED
        },
        {
            label: t('marketplace.promoted_offers'),
            value: OfferType.APPLIED
        },
        {
            label: t('marketplace.saved_offers'),
            value: OfferType.BOOKMARKED
        }
    ]

    const targetAudienceOptions: FilterOption[] = [
        {
            label: t('marketplace.offers_for_all'),
            value: ProfileType.ALL
        },
        {
            label: t('marketplace.offers_for_influencers'),
            value: ProfileType.INFLUENCER
        },
        {
            label: t('marketplace.offers_for_brands'),
            value: ProfileType.BRAND
        },
    ]



    const [openOfferCreator, setOpenOfferCreator] = useState<boolean>(false);
    const [offers, setOffers] = useState<IOffer[]>([]);
    const [serversideError, setServersideError] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState<FilterOption>(offerTypeOptions[0]);
    const [selectedAudience, setSelectedAudience] = useState<FilterOption>(targetAudienceOptions[0]);
    const [keywords, setKeywords] = useState<IKeyword[]>([]);
    const [location, setLocation] = useState<ILocation>(undefined);
    const [openMenu, setOpenMenu] = useState(false);
    const [selectedCollaboration, setSelectedCollaboration] = useState<ICollaboration>({
        freeProductPlacement: false,
        productPlacement: false,
        influencerCollaboration: false
    });
    const [typeFilterOpen, setTypeFilterOpen] = useState<boolean>(false);
    const [collaborationFilterOpen, setCollaborationFilterOpen] = useState<boolean>(false);
    const [targetAgeFilterOpen, setTargetAgeFilterOpen] = useState<boolean>(false);
    const [keywordFilterOpen, setKeywordFilterOpen] = useState<boolean>(false);
    const [locationFilterOpen, setLocationFilterOpen] = useState<boolean>(false);
    const [isMobile, setIsMobileView] = useState(window.innerWidth < 800);
    const [currentPage, setCurrentPage] = useState(1);
    const [seed] = useState(uuid());
    const [totalPages, setTotalPages] = useState(0);





    useEffect(() => {
        function handleResize() {
            setIsMobileView(window.innerWidth < 800);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    function onChangeKeywordFilter(keywords: IKeyword[]) {
        setKeywords(keywords);
    }

    function Menu() {
        return <span className={style.menu}>
            {isMobile ? <></> : <InputButton className={style.create_button} onClick={() => setOpenOfferCreator(true)}
                                             label={t('marketplace.create_offer')} />}
            <h2>{t('marketplace.filters')}:</h2>
            <KeywordFilter onChange={onChangeKeywordFilter} open={keywordFilterOpen} onOpen={setKeywordFilterOpen}
                           initialKeywords={keywords} />

            <InputFilter onOpen={setTypeFilterOpen} initialOption={selectedType}
                         onSelect={option => setSelectedType(option)} options={offerTypeOptions} open={typeFilterOpen} />
            <CollaborationFilter onOpen={setCollaborationFilterOpen} initialCollaboration={selectedCollaboration}
                                 open={collaborationFilterOpen} onChange={setSelectedCollaboration} />

            <InputFilter onOpen={setTargetAgeFilterOpen} initialOption={selectedAudience}
                         onSelect={option => setSelectedAudience(option)} options={targetAudienceOptions}
                         open={targetAgeFilterOpen} />
            <LocationFilter onOpen={setLocationFilterOpen} onChange={setLocation} open={locationFilterOpen}
                            initialLocation={location} />
        </span>
    }

    function MobileMenu() {
        const onCloseHandler = () => {
            setOpenMenu(false);
        };
        const handleMenuClick = (e: { stopPropagation: () => void; }) => {
            e.stopPropagation(); // Prevent click inside menu from closing the overlay
        };
        return (
            <>
                <InputButton className={style.create_button} onClick={() => setOpenMenu(true)}
                             label={t('marketplace.filters')} />
                <InputButton className={style.create_button} onClick={() => setOpenOfferCreator(true)}
                             label={t('marketplace.create_offer')} />
                <Overlay
                    open={openMenu}
                    onClose={onCloseHandler}
                    title={t('marketplace.filters')}
                >
                    <div onClick={handleMenuClick}>
                        <Menu />
                    </div>
                </Overlay>
            </>
        );
    }

    async function fetchOffers(page: number) {
        setOffers(undefined)
        try {
            const filter = {
                type: selectedType.value === 'all' ? undefined : selectedType.value,
                keywords: keywords.map(keyword => keyword.word),
                location: location,
                collaboration: selectedCollaboration,
                audience: selectedAudience.value === ProfileType.ALL ? undefined : selectedAudience.value,
                page: page,
                size: 25,
                seed
            } as OfferFilter

            const response = await marketplaceService.getOffers(filter)

            setOffers(response.offers)
            setTotalPages(response.totalPages)
        } catch (error) {
            const response = error.toJSON()

            if (response.status >= 500)
                setServersideError(true)
        }
    }

    useEffect(() => {
        fetchOffers(currentPage)
    }, [currentPage, selectedType, selectedCollaboration, selectedAudience, keywords, location]);


    if (serversideError)
        return <TechnicalError />

    return <>
        <section>
            {isMobile ? <MobileMenu /> : <Menu />}
            <OfferList
                offers={offers}
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(newPage) => {
                    setCurrentPage(newPage);
                    fetchOffers(newPage);
                }}
            />
            <OfferCreator open={openOfferCreator} onClose={offer => {
                setOpenOfferCreator(!openOfferCreator)
                if (offer) {
                    fetchOffers(currentPage)
                }

            }} />
        </section>
    </>
}

interface OfferListProps {
    offers: IOffer[];
    currentPage: number;
    totalPages: number;
    onPageChange: (newPage: number) => void;
}

const OfferList: React.FC<OfferListProps> = ({ offers, currentPage, totalPages, onPageChange }) => {
    return (
        <span className={style.offers}>
            {offers !== undefined ? offers.map(offer => <OfferOverview key={offer.ref} offer={offer} />) :
                <LoadingContent />}
            <Paging
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={onPageChange}
            />
        </span>
    );
}
