import React, { useState, useEffect } from 'react';
import ProfileView from '../../../../service/profile/model/profile-view.model';
import Panel from '../../../../theme/component/panel/panel.component';
import { useTranslation } from "react-i18next";
import style from './profile-progress.module.scss';
import SuccessIcon from "../../../../theme/resource/reactIcons/successIcon";
type ProfileKey = keyof ProfileView;

const Progress: React.FC<{ value: number }> = ({ value }) => {
    return (
        <div className={style.progressContainer}>
            <div
                className={style.progressBar}
                style={{ width: `${value}%` }}
            >
                <span className={style.progressText}>{Math.round(value)}%</span>
            </div>
        </div>
    );
};



type ProfileProgressProps = {
    profile: ProfileView;
};

const ProfileProgress: React.FC<ProfileProgressProps> = ({ profile }) => {
    const [progress, setProgress] = useState(0);
    const [isComplete, setIsComplete] = useState(false);
    const { t } = useTranslation();

    const checklistItems: Array<{ key: ProfileKey; label: string }> = [
        { key: 'displayname', label: t('profile_progress.displayname') },
        { key: 'avatar', label: t('profile_progress.avatar') },
        { key: 'banner', label: t('profile_progress.banner') },
        { key: 'description', label: t('profile_progress.description') },
        { key: 'networks', label: t('profile_progress.networks') },
        { key: 'keywords', label: t('profile_progress.keywords') },
        { key: 'collaborations', label: t('profile_progress.collaborations') },
        { key: 'demographics', label: t('profile_progress.demographics') },
        { key: 'location', label: t('profile_progress.location') },
    ];

    useEffect(() => {
        const completedItems = checklistItems.filter(item => {
            const value = profile[item.key];
            if (value === undefined || value === null) return false;

            switch (item.key) {
                case 'avatar':
                case 'banner':
                case 'displayname':
                case 'description':
                    return typeof value === 'string' && value.length > 0;
                case 'location':
                    return typeof value === 'object' && 'country' in value && 'province' in value && 'city' in value;
                case 'demographics':
                    return typeof value === 'object' && 'targetGender' in value && 'targetAge' in value;
                case 'collaborations':
                    return typeof value === 'object' && Object.values(value).some(v => v === true);
                case 'networks':
                    return typeof value === 'object' && 'totalFollower' in value && value.totalFollower > 0;
                case 'keywords':
                    return Array.isArray(value) && value.length > 0;
                default:
                    return false;
            }
        });
        const newProgress = (completedItems.length / checklistItems.length) * 100;
        setProgress(newProgress);
        setIsComplete(newProgress === 100);
    }, [profile]);

    if (isComplete)
        return null;

    return (
        <Panel className={style.panel}>
            <h1 className={style.title}>{t('profile_progress.title')}</h1>
            <Progress value={progress} />
            <ul>
                {checklistItems.map((item) => {
                    const value = profile[item.key];
                    let isCompleted = false;
                    if (value !== undefined && value !== null) {
                        switch (item.key) {
                            case 'avatar':
                            case 'banner':
                            case 'displayname':
                            case 'description':
                                isCompleted = typeof value === 'string' && value.length > 0;
                                break;
                            case 'location':
                                isCompleted = typeof value === 'object' && 'country' in value && 'province' in value && 'city' in value;
                                break;
                            case 'demographics':
                                isCompleted = typeof value === 'object' && 'targetGender' in value && 'targetAge' in value;
                                break;
                            case 'collaborations':
                                isCompleted = typeof value === 'object' && Object.values(value).some(v => v === true);
                                break;
                            case 'networks':
                                isCompleted = typeof value === 'object' && 'totalFollower' in value && value.totalFollower > 0;
                                break;
                            case 'keywords':
                                isCompleted = Array.isArray(value) && value.length > 0;
                                break;
                        }
                    }
                    return (
                        <li key={item.key} className={style.checklistItem}>

                            <span className={isCompleted ? style.icon : style.iconGray}></span>
                            <div className={style.listItem}>
                                {item.label}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </Panel>
    );
};

export default ProfileProgress;