import style from './index.module.scss';

import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Login from "../../../login";

export default function JoinSection() {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return <section className={style.section}>
        <h1>Interested?</h1>
        <h3 className={'mb4'}>Sign up and be a part of Influspace!</h3>
        <Login onLogin={() => {}}/>
    </section>
    // return <section className={style.container}>
    //     <h2>{t('join_section.title')}</h2>
    //     <p className={'mb1 mt1'}>{t('join_section.subtitle')}</p>
    //     <div className={style.join_button} onClick={() => navigate('/join')}>{t('join_section.call_to_action')}</div>
    // </section>
}