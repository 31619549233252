import React, {useEffect, useRef, useState} from "react";
import Overlay from "../../../../theme/component/overlay/overlay.component";
import InputText from "../../../../theme/component/input-text/input-text.component";
import InputButton from "../../../../theme/component/input-button";
import InputTextArea from "../../../../theme/component/input-text-area/input-text-area.component";
import {
    IKeyword
} from "../../../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";
import {
    KeywordTextArea
} from "../../../profile/component/profile-audience/component/profile-keywords/component/keyword-textarea.component";
import KeywordTag
    from "../../../profile/component/profile-audience/component/profile-keywords/component/keyword-tag.component";
import {v4 as uuid} from 'uuid'
import style from './index.module.scss'
import marketplaceService from "../../../../service/marketplace/marketplace.service";
import MarketplaceService from "../../../../service/marketplace/marketplace.service";
import {IOffer, ProfileType} from "../../../../service/marketplace/model/offer.model";
import InputBox from "../../../../theme/component/input-box/input-box.component";
import {IOfferRequest} from "../../../../service/marketplace/model/offer-request.model";
import OptionModel from "../../../../theme/component/input-dropdown/models/dropdown-option.model";
import {LocationInputText} from "../../../../theme/component/location-input-text";
import ImageUpload from "../../../profile/component/profile-header/component/image-upload/image-upload.component";
import imageService from "../../../../service/image/image.service";
import {ImageType} from "../../../../service/image/models/image-type.model";
import {NotificationService} from "../../../../service/notification/notification.service";
import {NotificationType} from "../../../../service/notification/model/notification-type.enum";
import ProfileService from "../../../../service/profile/profile.service";
import {useTranslation} from "react-i18next";
import SessionService from "../../../../service/session/session.service";
import {ButtonColor} from "../../../../theme/component/input-button/models/button-color.model";
import Loading from "../../../../theme/layout/loading/loading.component";
import LoadingContent from "../../../../theme/layout/loading-spinner/loading-spinner.component";
import LoadingAiComponenet from "../../../../theme/layout/loading-ai/loading-ai.componenet";
import LoadingAiAnimation from "../../../../theme/layout/loading-ai/loading-ai.componenet";

const audienceOptions: OptionModel[] = [
    { label: 'Brand & Influencer', value: ProfileType.ALL },
    { label: 'Brand', value: ProfileType.BRAND },
    { label: 'Influencer', value: ProfileType.INFLUENCER },
]

type OfferCreatorProps = {
    offer?: IOffer
    open: boolean
    onClose: (offer: IOffer) => void
}
export default function OfferCreator({ open, onClose, offer }: OfferCreatorProps) {

    const [description, setDescription] = useState<string>(offer?.description)
    const [name, setName] = useState<string>(offer?.name)
    const [keywordTags, setKeywordTags] = useState<JSX.Element[]>()
    const [keywords, setKeywords] = useState<IKeyword[]>(offer?.keywords ? offer.keywords : [])
    const [freeProductPlacement, setFreeProductPlacement] = useState<boolean>(true)
    const [productPlacement, setProductPlacement] = useState<boolean>(true)
    const [influencerCollaboration, setInfluencerCollaboration] = useState<boolean>(true)
    const [audience, setAudience] = useState<string>('all')
    const [location, setLocation] = useState<ILocation>(offer?.location)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [errorField, setErrorField] = useState<string>('')
    const [image, setImage] = useState<string>('')
    const [displayImage, setDisplayImage] = useState<string>('')
    const [premiumOnly, setPremiumOnly] = useState<boolean>(false)
    const [influencerSize, setInfluencerSize] = useState<string>('')
    const [youtube, setYoutube] = useState<boolean>(false)
    const [instagram, setInstagram] = useState<boolean>(false)
    const [twitter, setTwitter] = useState<boolean>(false)
    const [tiktok, setTiktok] = useState<boolean>(false)
    const [twitch, setTwitch] = useState<boolean>(false)
    const [aISuggestionInput, setAISuggestionInput] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false);

    const {t} = useTranslation()
    const profileType = SessionService.getMeDetails().profileType
    const overlayRef = useRef<HTMLDivElement>(null);


    const [keywordSuggestions, setKeywordSuggestions] = useState<string[]>([])

    async function fetchKeyWordSuggestions() {
        try {
            setKeywordSuggestions(await ProfileService.getKeywordSuggestions())
        } catch(error) {
            console.log(error)
        }
    }


    async function onUpdateKeywords(newKeyword: IKeyword) {
        if (keywords.find((keyword) => keyword.word === newKeyword.word) !== undefined)
            return

        const existingKewordIndex = keywords.findIndex((keyword) => keyword.ref === newKeyword.ref)
        if (existingKewordIndex !== -1) {
            keywords[existingKewordIndex] = newKeyword
        } else {
            newKeyword.ref = uuid()
            keywords?.push(newKeyword)
        }
        setKeywords([...keywords])
    }

    async function onDeleteKeyword(newKeyword: IKeyword) {
        try {
            setKeywords(keywords.filter((keyword) => keyword.word !== newKeyword.word))
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setKeywordTags(keywords?.map((keyword) => <KeywordTag onChange={onUpdateKeywords} key={keyword.ref}
            onDelete={onDeleteKeyword} keyword={keyword}
            editingMode={true} />))
    }, [keywords])

    useEffect(() => {
        setName(offer?.name)
        setDescription(offer?.description)
        setFreeProductPlacement(!!offer?.collaborations?.freeProductPlacement)
        setProductPlacement(!!offer?.collaborations?.productPlacement)
        setInfluencerCollaboration(!!offer?.collaborations?.influencerCollaboration)
        setKeywords(offer?.keywords ? offer.keywords : [])
        setKeywordTags(offer?.keywords?.map((keyword) => <KeywordTag onChange={onUpdateKeywords} key={keyword.ref}
            onDelete={onDeleteKeyword} keyword={keyword}
            editingMode={true} />))
        setAudience(offer?.audience ? offer.audience : (profileType === 'influencer' ? audienceOptions[1].value : audienceOptions[2].value))
        setPremiumOnly(offer?.premiumOnly ?? false)
        fetchKeyWordSuggestions()
    }, [open]);



    function isFormValid() {
        if (!name || name.length == 0) {
            setErrorMessage('Name required')
            setErrorField('name')
            return false;
        }
        if (!description || description?.length == 0) {
            setErrorMessage('description required')
            setErrorField('description')
            return false;
        }
        if (keywords?.length == 0) {
            setErrorMessage('Keywords required')
            setErrorField('keywords')
            return false;
        }
        return true
    }

    async function onSave() {
        if (!isFormValid())
            return

        try {
            const newOffer = {
                name: name,
                description: description,
                keywords: keywords.map(keyword => keyword.word),
                collaborations: {
                    freeProductPlacement: freeProductPlacement,
                    productPlacement: productPlacement,
                    influencerCollaboration: influencerCollaboration
                },
                audience: audience,
                location: location,
                image: image,
                premiumOnly: premiumOnly
            } as IOfferRequest

            console.log(newOffer)
            NotificationService.displayNotification({
                message: offer?.ref ? 'offer updated':`offer created`,
                type: NotificationType.success,
                duration: 3
            })
            onClose(offer?.ref ? await marketplaceService.updateOffer(offer.ref, newOffer) : await marketplaceService.saveOffer(newOffer))
            cleanUpForm()
        } catch (error) {
            const webError = error.response.data
            setErrorMessage(webError.description)
            setErrorField(webError.field)

            console.log(error.response.data)
        }
    }

    //to-do
    const handleAISuggest = async () => {
        setIsLoading(true);
        try {
            const response = await MarketplaceService.getAICampaignSuggestion(aISuggestionInput);

            if (response) {
                const { name, description, keywords, collaborations, audience, premiumOnly } = response;

                setName(name);
                setDescription(description);
                setKeywords(keywords.map((keyword) => ({ word: keyword, ref: uuid() })));
                setKeywordTags(keywords.map((keyword) =>
                    <KeywordTag
                        onChange={onUpdateKeywords}
                        key={uuid()}
                        onDelete={onDeleteKeyword}
                        keyword={{ word: keyword, ref: uuid() }}
                        editingMode={true}
                    />
                ));
                setFreeProductPlacement(collaborations.freeProductPlacement);
                setProductPlacement(collaborations.productPlacement);
                setInfluencerCollaboration(collaborations.influencerCollaboration);
                setAudience(audience);
                setPremiumOnly(premiumOnly);
            } else {
                console.error('Failed to fetch AI suggestion:', response);
                NotificationService.displayNotification({
                    message: 'Failed to fetch AI suggestion',
                    type: NotificationType.failure,
                    duration: 3
                });
            }
        } catch (error) {
            console.error('Error fetching AI suggestion:', error);
            NotificationService.displayNotification({
                message: 'Error fetching AI suggestion',
                type: NotificationType.failure,
                duration: 3
            });
        } finally {
            setIsLoading(false);
        }
    };



    function cleanUpForm() {
        setKeywords([])
        setDescription('')
        setFreeProductPlacement(true)
        setProductPlacement(true)
        setInfluencerCollaboration(true)
        setErrorField('')
        setErrorMessage('')
        setImage('')
        setPremiumOnly(false)
    }


    const handleImageUpload = (file: File) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            // The result includes the data URL
            const dataURL = reader.result as string;
            setDisplayImage(dataURL); // Assuming this sets the image source
        };

        reader.readAsDataURL(file); // This triggers the onloadend event

        imageService.uploadImage(file, ImageType.Other)
            .then(response => {
                const url = new URL(response.url, window.location.origin);
                const refId = url.searchParams.get('ref');
                setImage(refId)
            })
            .catch(error => {
                console.error('Error uploading image', error);
            });
    };

    function onCloseHandler() {
        onClose(offer)
        cleanUpForm()
    }

    function KeywordSuggestion({word, onClick}: {word: string, onClick: (word: string) => void}) {
        return <span className={style.tag_view} onClick={() => onClick(word)}>{word}</span>

    }

    function addSuggestion(word: string) {
        onUpdateKeywords({word})
    }

    console.log(isLoading)
    return <>
        <Overlay className={style.overlay} titleClass={style.title} disableTapout={true} title={t('marketplace.offer_creator_title')} open={open}
                 onClose={onCloseHandler}>
            <h4 className={style.motivation}>{t('marketplace.offer_creator_motivation')}</h4>
            <div className={style.AIBox}>
                <h3 className={style.AIText}>Generate with AI</h3>
                {isLoading ? <LoadingAiAnimation/>:
                    <div className={style.AIInnerBox}>
                    <InputTextArea
                        error={errorField === 'name' ? errorMessage : ''}
                        onChange={value => setAISuggestionInput(value)}
                        className={style.AIInput}
                        placeholder="What's your campaign about?"
                        rows={3}
                    initialValue={''}
                    />
                <div className={style.AIButton}><InputButton color={ButtonColor.BLACK} onClick={ ()=> handleAISuggest()} label='Suggest'/></div>
            </div>}
            </div>
            <div className={style.header}>{t('marketplace.offer_creator_audience')}</div>
            <div className={style.inputContainer}>
                <InputBox
                    icon='brand'
                    label='Brand'
                    selected={audience === 'all' || audience === 'brand'}
                    onChange={(selected) =>
                        setAudience(!selected ? 'influencer' : (audience === 'influencer' ? 'all' : 'brand'))}
                />
                <InputBox
                    icon='influencer_gradient'
                    label='Influencer'
                    selected={audience === 'all' || audience === 'influencer'}
                    onChange={(selected) =>
                        setAudience(!selected ? 'brand' : (audience === 'brand' ? 'all' : 'influencer'))}
                />
            </div>

            {/*<h4>Find out more about Campaign recommendations here</h4>*/}
            <div className='mt1'>
                <div className={style.header}>{t('marketplace.offer_creator_name')}</div>

                <InputText error={errorField === 'name' ? errorMessage : ''}
                                            onChange={value => setName(value)} focus={true}
                                            placeholder='My Offer' initialValue={name}/>
            </div>
            <div className="mt1">
                <div className={style.header}>{t('marketplace.offer_creator_description')}</div>

                <InputTextArea error={errorField === 'description' ? errorMessage : ''}
                                                onChange={value => setDescription(value)}
                                                placeholder={'This is my offer'} initialValue={description}/>
            </div>

            <div className='text-left'>
                <span className={style.header}>{t('marketplace.offer_creator_keywords')}</span>
                <KeywordTextArea hintService={MarketplaceService} error={errorField === 'keywords' ? errorMessage : ''}
                                 max={10} onChange={onUpdateKeywords}>{keywordTags}</KeywordTextArea>
                <div className={'mt1'}>
                    {keywordSuggestions.map((suggestion) => <KeywordSuggestion word={suggestion} onClick={addSuggestion}/>)}
                </div>
            </div>

            <div className="mt1">
                <span className={style.header}>{t('marketplace.offer_creator_photo')}</span>
                <div className={style.grid_container}>
                    <div className={style.avatar}>
                        <div className={style.image_upload}>
                            <ImageUpload editingMode={true} uploadImage={handleImageUpload}/>
                        </div>
                    </div>
                    <div className={style.image_container}>
                        {image && <img className={style.image} src={displayImage} alt="Uploaded Offer Image"/>}
                    </div>
                </div>
            </div>

            <div className={'mt1'}>
                <span className={style.header}>{t('marketplace.offer_creator_location')}</span>
                <LocationInputText initialLocation={location} onChange={location => setLocation(location)}/>

            </div>


            <div className='mt1'>
                <span className={style.header}>{t('marketplace.offer_creator_collaboration')}</span>
                <div className={`mb1 ${style.inputContainer}`}>
                    <div className='mb1'>
                        <InputBox icon='influencer' label='Collaborations' selected={influencerCollaboration}
                                  onChange={(value) => setInfluencerCollaboration(value)}/>
                    </div>
                    <div className='mb1'>
                        <InputBox icon='free_placement' label='Free Product' selected={freeProductPlacement}
                                  onChange={(value) => setFreeProductPlacement(value)}/>
                    </div>
                    <div className='mb1'>
                        <InputBox icon='product_placement' label='Placement' selected={productPlacement}
                                  onChange={(value) => setProductPlacement(value)}/>
                    </div>
                </div>
            </div>
            {/*<div className='mt1'>*/}
            {/*    <span className={style.label}>Where should your Influencer shine?</span>*/}
            {/*    <div className={`mb1 ${style.inputContainer}`}>*/}
            {/*        <InputBox icon='instagram' label='Instagram' selected={instagram}*/}
            {/*                  onChange={(value) => setInstagram(value)}/>*/}
            {/*        <InputBox icon='youtube' label='Youtube' selected={youtube}*/}
            {/*                  onChange={(value) => setYoutube(value)}/>*/}
            {/*        <InputBox icon='twitch' label='Twitch' selected={twitch}*/}
            {/*                  onChange={(value) => setTwitch(value)}/>*/}
            {/*        <InputBox icon='twitter' label='Twitter' selected={twitter}*/}
            {/*                  onChange={(value) => setTwitter(value)}/>*/}
            {/*        <InputBox icon='tiktok' label='Tiktok' selected={tiktok}*/}
            {/*                  onChange={(value) => setTiktok(value)}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className='mt1'>*/}
            {/*    <span className={style.label}>Set Influencer Size</span>*/}
            {/*    <div className={`mb1 ${style.inputContainer}`}>*/}
            {/*        <InputBox icon='a' label='Micro' selected={influencerSize === 'micro'}*/}
            {/*                  onChange={(value) => setInfluencerSize('micro')}/>*/}
            {/*        <InputBox icon='b' label='Small' selected={influencerSize === 'small'}*/}
            {/*                  onChange={(value) => setInfluencerSize('small')}/>*/}
            {/*        <InputBox icon='c' label='Medium' selected={influencerSize === 'medium'}*/}
            {/*                  onChange={(value) => setInfluencerSize('medium')}/>*/}
            {/*        <InputBox icon='d' label='Large' selected={influencerSize === 'large'}*/}
            {/*                  onChange={(value) => setInfluencerSize('large')}/>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='mt1'>
                <span className={style.header}>{t('marketplace.offer_creator_visibility')}</span>
                <div className={`mb1 ${style.inputContainer}`}>
                    <InputBox icon='community' label='Everyone' selected={!premiumOnly}
                              onChange={(value) => setPremiumOnly(!value)}/>
                    <InputBox icon='premium' label='Premium Only' selected={premiumOnly}
                              onChange={(value) => setPremiumOnly(value)}/>
                </div>
            </div>
            <div className="mt3">
                <span className='float-right'><InputButton onClick={onSave} label={offer ? 'Save' : 'Publish'}/></span>
            </div>
        </Overlay>
    </>
}