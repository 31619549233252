import React from "react";
import style from './index.module.scss';
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import HeroLogin from "../../../../components/common/hero-login";
import {HeroCall} from "./component/hero-call";

export function HeroSection2() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return <div className={style.container}>
        <span className={style.bottom_decoration} />
        <section className={style.section}>
            <div className={style.box}>
                <div className={style.inner}>
                    <HeroCall />
                </div>
            </div>
            <div className={style.box}>
                <div className={style.inner}>
                    <HeroLogin />
                </div>
            </div>
        </section>
    </div>
}