import React, { useRef } from 'react';
import style from './image-upload.module.scss';

interface ChatImageUploadProps {
    onImagesSelect: (files: File[]) => void;
    useEdge?: boolean;
}

const ChatImageUpload: React.FC<ChatImageUploadProps> = ({ onImagesSelect, useEdge }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const imageFiles = Array.from(files).filter(file => file.type.startsWith('image/'));
            onImagesSelect(imageFiles);
        }
    };

    const uploadClass = useEdge ? `${style.chatImageUpload} ${style.chatImageUploadEdge}` : style.chatImageUpload;

    return (
        <label className={uploadClass}>
            <input
                type="file"
                accept="image/png,image/jpg,image/jpeg"
                onChange={handleFileChange}
                ref={fileInputRef}
                multiple
            />
        </label>
    );
};

export default ChatImageUpload;